<template>
  <div>
    <a-modal v-model="visible" width="750px" :confirmLoading="loading" :maskClosable="false" @cancel="cancel"
      @ok="confirm">
      <div slot="title">{{ form.id ? '编辑物料属性' : '新增物料属性' }}</div>
      <div>

        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4, md: 8 }"
          :wrapper-col="{ span: 20, md: 16 }">
          <a-row gutter="12">
            <a-col :span="24" :md="12">
              <a-form-model-item prop="name" label="属性名称">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
            <a-form-model-item prop="category" label="分类">
                <a-select v-model.number="form.category" style="width: 100%" :allowClear="true">
                  <a-select-option
                    v-for="item of classificationItems"
                    :key="item.id"
                    :value="item.id">{{ item.name }}
                  </a-select-option>
                </a-select>
            </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="unit" label="单位">
                <a-select v-model.number="form.unit" :allowClear="true">
                  <a-select-option
                    v-for="item of unitItems"
                    :key="item.id"
                    :value="item.id">{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            
            </a-col>
            
           

            <a-col :span="24" :md="12">
              <a-form-model-item prop="required" label="是否必填">
                <a-switch checked-children="启用" un-checked-children="禁用" v-model="form.required" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="name_composition" label="名称组成">
                <a-switch checked-children="启用" un-checked-children="禁用" v-model="form.name_composition" />
              </a-form-model-item>
            </a-col>

          </a-row>





        </a-form-model>

      </div>
    </a-modal>
  </div>
</template>

<script>
import { materialsAttrCreate, materialsAttrUpdate } from '@/api/materials'

export default {
  name: 'FormModal',
  props: ['visible', 'form','classificationItems', 'unitItems'],
  model: { prop: 'visible', event: 'cancel' },
  data() {
    return {
      rules: {
        name: [{ required: true, message: '请输入属性名称', trigger: 'change' },
        { max: 64, message: "超出最大长度 (64)", trigger: "change" },],
        category:[{ required: true, message: '请选择类目', trigger: 'change' },]
      },
      loading: false,
    };
  },
  methods: {
    confirm() {

      console.log(this.form) 
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? materialsAttrUpdate : materialsAttrCreate;
          func(this.form).then(data => {
            this.$message.success(this.form.id ? '修改成功' : '新增成功');
            this.$emit(this.form.id ? 'update' : 'create', data);
            this.cancel();
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },
    cancel() {
      this.$emit('cancel', false);
      this.$refs.form.resetFields();
    },
  },
}
</script>

<style scoped></style>